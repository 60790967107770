<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-auto pa-8"
        max-width="100%"
        outlined
      >
        <CardToolbar
          :icon="REPORTS_ICON"
          button-mode
          include-help
          title="Reports"
        />
        <v-card-text class="mt-n4">
          <p :class="`${DEFAULT_TEXT}`">A list of available reports to run against the iCare database.</p>
        </v-card-text>

        <HelpText :page="REPORTS_VUE" />

        <v-data-table
          id="reports-table"
          :footer-props="{ 'items-per-page-options': [-1] }"
          :headers="reportHeaders"
          :items="reportList"
          :items-per-page="-1"
          :loading="isLoading"
          @click:row="viewReport"
          class="mx-4"
          fixed-header
          hide-default-footer
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
          sort-by="report_type"
        />
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { now, transformError } from '../services/utility.service'
import { ICONS, TEXT_COLOR, ROUTES, VUES } from '../constants'
import { push } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
  },
  data: () => ({
    hasReportList: false,
    hasReportTypeList: false,
    hasReportParameterList: false,

    reportHeaders: [
      { text: 'Report', align: 'left', value: 'name' },
      { text: 'Description', align: 'left', value: 'description' },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      reportList: 'report/getReportList',
      reportTypeList: 'report/getReportTypeList',
    }),
    /**
     * Metadata required by API calls.
     */
    apiCallMetadata() {
      return { operator_id: this.user.id, dlc: now(), }
    },
    isLoading() {
      return !this.hasReportList && !this.hasReportTypeList
    },
  },
  methods: {
    ...mapActions({
      fetchReportsList: 'report/fetchReportsList',
      fetchReportTypesList: 'report/fetchReportTypesList',
      fetchReportParameterList: 'report/fetchReportParameterList',
      getReport: 'report/getReport',
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
    }),
    getReportList() {
      this.fetchReportsList()
        .then(() => this.hasReportList = true)
        .catch(error => this.errorMessage(`Failed to load report list. ${transformError(error)}`))
      
      this.fetchReportTypesList()
        .then(() => this.hasReportTypeList = true)
        .catch(error => this.errorMessage(`Failed to load report-types list. ${transformError(error)}`))

      this.fetchReportParameterList()
        .then(() => this.hasReportParameterList = true)
        .catch(error => this.errorMessage(`Failed to load report-parameter list. ${transformError(error)}`))
    },
    initTemplateConstants () {
      this.REPORTS_ICON = ICONS.REPORTS
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.REPORTS_VUE = VUES.REPORTS
    },
    viewReport(event) {
      push(`${ROUTES.REPORTS}/${event.id}`)
    },
  },
  mounted() {
    this.getReportList()
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>

<style scoped>
button {
  outline: none;
}
#reports-table {
  cursor: pointer;
}
</style>